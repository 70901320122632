import React, { useEffect, useRef, useState } from "react";
import "../App.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import PhoneInput from 'react-phone-number-input/input'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function ClientForm() {
  const form = useRef();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState("");
  const [phone, setPhone] = useState()
  const [ephone, setEphone] = useState()
  const [showform, setShowform] = useState(false);
  const [showcheck, setShowcheck] = useState(false);
  const [selectOption, setSetSelectOption] = useState("Medical reason")
  const [getCheckBox, setGetCheckBox] = useState(false)
  const [textAreavalue, setTextAreavalue] = useState("")
  const [selectedValue, setSelectedValue] = useState('');

  const [allFields, setAllFields] = useState({
    user_fname: '',
    user_lname: '',
    address: '',
    street: '',
    city: '',
    province: '',
    postal: '',
    user_email: '',
    emergency: '',
    lymphedema: '',
    lymphNodes: '',
    needs: '',
    medicalreason1: '',
    fibromyalgia: '',
    surgeries: '',
    user_email: '',
    pregnant: false,
    acuteInfection: false,
    immunosuppressants: false,
    electricalImplants: false,
    dvt: false,
    cardiacEdema: false,
    renalFailure: false,
    any: false,
    showinput: '',
    reason: '',
    sinuscongestion: '',
    sinusproblems: '',
    earaches: '',
    arthritis: '',
    inflamamation: '',
    fibrocysticbreast: '',
    majorscars: '',
    varicoseveins: '',
    strokes: '',
    seizures: '',
    fibrocysticbreast: '',
    currentlybreastfeeding: '',
    currentlypregnant: '',
    ch_dvt: '',
    ch_box_1: false,
    ch_box_2: false,
    ch_box_3: false,
    ch_box_4: false,
    liposuction: '',
    breast: '',
    other1: '',
    date: '',
    dob: '',
    ldate: '',
    printname: ''
  })
  const notify = () => toast("Wow so easy!");
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setAllFields((prevData) => ({
      ...prevData,
      [name] : value,
      // [name]: type === 'radio' ? checked : value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 4000);
    // http://intakecontactform.test/api/send/mail
    // https://bzbeetech.com/IntakeContactForm/api/send/mail
    axios
      .post(`https://bzbeetech.com/IntakeContactForm/api/send/mail`, {
        user_fname: allFields.user_fname,
        user_lname: allFields.user_lname,
        address: allFields.address,
        street: allFields.street,
        city: allFields.city,
        province: allFields.province,
        postal: allFields.postal,
        user_email: allFields.user_email,
        emergency: allFields.emergency,
        lymphedema: allFields.lymphedema,
        lymphNodes: allFields.lymphNodes,
        needs: allFields.needs,
        medicalreason1: allFields.medicalreason1,
        surgeries: allFields.surgeries,
        fibromyalgia: allFields.fibromyalgia,
        pregnant: allFields.pregnant,
        acuteInfection: allFields.acuteInfection,
        immunosuppressants: allFields.immunosuppressants,
        electricalImplants: allFields.electricalImplants,
        dvt: allFields.dvt,
        cardiacEdema: allFields.cardiacEdema,
        renalFailure: allFields.renalFailure,
        any: allFields.any,
        showinput: allFields.showinput,
        reason: allFields.reason,
        sinuscongestion: allFields.sinuscongestion,
        sinusproblems: allFields.sinusproblems,
        earaches: allFields.earaches,
        arthritis: allFields.arthritis,
        inflamamation: allFields.inflamamation,
        fibrocysticbreast: allFields.fibrocysticbreast,
        majorscars: allFields.majorscars,
        varicoseveins: allFields.varicoseveins,
        strokes: allFields.strokes,
        seizures: allFields.seizures,
        currentlybreastfeeding: allFields.currentlybreastfeeding,
        currentlypregnant: allFields.currentlypregnant,
        ch_dvt: allFields.ch_dvt,
        ch_box_1: allFields.ch_box_1,
        ch_box_2: allFields.ch_box_2,
        ch_box_3: allFields.ch_box_3,
        ch_box_4: allFields.ch_box_4,
        liposuction: allFields.liposuction,
        breast: allFields.breast,
        other1: allFields.other1,
        date: allFields.date,
        dob: allFields.dob,
        ldate: allFields.ldate,
        phone: phone,
        ephone: ephone,
        printname: allFields.printname,

      })
      .then((res) => {
      
        toast.success("Thanks, Your message send successfully!", {
          theme: "dark",
          position: "bottom-center",
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000)
      })
      .catch((error) => {
        console.error(error);
        toast.error("Please Fill All Required Fields", {
          autoClose: 2000,
          theme: "dark",
          position: "bottom-center",
        });
      });
  };

  const handleCheckBox = (e) => {
    setGetCheckBox(e.target.value)
  }

  return (
    <div className="container-fluid">
      <div className="container">
        <div className="row " style={{ margin: "auto", justifyContent: 'center' }}>
          <div >{''}</div>
          <form >
            <div>
              <div
                className="col-12"
                style={{
                  color: "#2c3345",
                  fontSize: "2em",
                  fontWeight: 600,
                  lineHeight: "1.45",
                  borderBottom: "1px solid #f3f3fe",
                }}
              >
                <h1>New Client Intake Form</h1>
              </div>{" "}
            </div>

            <br />
            <div className="row">
              <div className="col-md-6 pt-5">
                <div style={{ marginTop: "1px" }}>
                  ( <span style={{ color: "red", fontSize: "1.3rem" }}>*</span> Indicates Mandatory Fields )
                </div>
                <label htmlFor="date" className="form-label label">
                  Today's Date{" "}
                  <span style={{ color: "red", fontSize: "1.3rem" }}>*</span>{" "}
                </label>
                <input
                  type="date"
                  className="form-control inputs"
                  id="date"
                  required
                  name="date"
                  value={allFields.date}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 pt-5">
                <label htmlFor="fname" className="form-label label">
                  First Name{" "}
                  <span style={{ color: "red", fontSize: "1.3rem" }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control inputs"
                  id="fname"
                  value={allFields.user_fname}
                  onChange={handleChange}
                  required
                  name="user_fname"
                />
              </div>

              <div className="col-md-6 pt-5">
                <label htmlFor="lname" className="form-label label">
                  Last Name{" "}
                  <span style={{ color: "red", fontSize: "1.3rem" }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control inputs"
                  id="lname"
                  value={allFields.user_lname}
                  onChange={handleChange}
                  required
                  name="user_lname"
                />
              </div>

            </div>

            <div className="row">
              <div className="col-md-6 pt-5">
                <label htmlFor="dob" className="form-label label">
                  DOB{" "}
                  <span style={{ color: "red", fontSize: "1.3rem" }}>*</span>
                </label>
                <input
                  type="date"
                  className="form-control inputs"
                  id="dob"
                  required
                  name="dob"
                  value={allFields.dob}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="row">

              <div className="col-12 pt-5">
                <label htmlFor="address" className="form-label label">
                  Address{" "}
                  <span style={{ color: "red", fontSize: "1.3rem" }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control inputs"
                  id="address"
                  value={allFields.address}
                  onChange={handleChange}
                  required
                  name="address"
                  autoComplete="off"
                />
                <label htmlFor="address" className="form-label sub-label">
                  Street Address{" "}
                  <span style={{ color: "red", fontSize: "1.3rem" }}>*</span>
                </label>
              </div>

              <div className="col-12 pt-3">
                <input
                  type="text"
                  className="form-control inputs"
                  id="street"
                  value={allFields.street}
                  onChange={handleChange}
                  name="street"
                />
                <label htmlFor="street" className="form-label sub-label">
                  Street Address Line 2{" "}
                </label>
              </div>

              <div className="col-md-6 pt-3">
                <input
                  type="text"
                  className="form-control inputs"
                  id="city"
                  value={allFields.city}
                  onChange={handleChange}
                  required
                  name="city"
                />
                <label htmlFor="city" className="form-label sub-label">
                  City <span style={{ color: "red", fontSize: "1.3rem" }}>*</span>
                </label>
              </div>

              <div className="col-md-6 pt-3">
                <input
                  type="text"
                  className="form-control inputs"
                  id="province"
                  value={allFields.province}
                  onChange={handleChange}
                  required
                  name="province"
                />
                <label htmlFor="province" className="form-label sub-label">
                  State / Province{" "}
                  <span style={{ color: "red", fontSize: "1.3rem" }}>*</span>
                </label>
              </div>

              <div className="col-12 pt-3">
                <input
                  type="text"
                  className="form-control inputs"
                  id="postalCode"
                  value={allFields.postal}
                  onChange={handleChange}
                  required
                  name="postal"
                />
                <label htmlFor="postalCode" className="form-label sub-label">
                  Postal / Zip Code{" "}
                  <span style={{ color: "red", fontSize: "1.3rem" }}>*</span>
                </label>
              </div>

              <div className="col-md-6 pt-5">
                <label htmlFor="phoneNo" className="form-label label">
                  Phone{" "}
                  <span style={{ color: "red", fontSize: "1.3rem" }}>*</span>
                </label>

                <PhoneInput
                  value={phone}


                  country="US"
                  maxLength={14}
                  className="form-control inputs"
                  id="phoneNo"
                  placeholder="(000) 000-0000"
                  required
                  onChange={setPhone}
                  name="phone"
                />


                <label htmlFor="phoneNo" className="form-label sub-label">
                  Please enter a valid phone number.{" "}
                </label>
              </div>

              <div className="col-md-6 pt-5">
                <label htmlFor="email" className="form-label label">
                  Email{" "}
                  <span style={{ color: "red", fontSize: "1.3rem" }}>*</span>
                </label>
                <input
                  type="email"
                  className="form-control inputs"
                  id="email"
                  required
                  name="user_email"
                  value={allFields.user_email}
                  onChange={handleChange}
                  autoComplete="off"
                />
                <label htmlFor="email" className="form-label sub-label">
                  example@example.com{" "}
                </label>
              </div>

              <div className="col-md-6 pt-5">
                <label htmlFor="emergency" className="form-label label">
                  In case of emergency{" "}
                  <span style={{ color: "red", fontSize: "1.3rem" }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control inputs"
                  id="emergency"
                  value={allFields.emergency}
                  onChange={handleChange}
                  required
                  name="emergency"
                />
              </div>

              <div className="col-md-6 pt-5">
                <label htmlFor="ephone" className="form-label label">
                  Phone{" "}
                  <span style={{ color: "red", fontSize: "1.3rem" }}>*</span>
                </label>
                <PhoneInput

                  value={ephone}
                  country="US"
                  className="form-control inputs"
                  id="ephone"
                  placeholder="(000) 000-0000"
                  required
                  maxLength={14}
                  name="ephone"
                  onChange={setEphone} />
                <label htmlFor="ephone" className="form-label sub-label">
                  Please enter a valid phone number.{" "}
                </label>
              </div>

              {/*--Setion2---*/}

              <div className="col-12 section2-head mt-5">
                <h6>
                  Please note: The following is a list of those who can not
                  participate in Lymphatic Therapy.{" "}
                  <span style={{ color: "red", fontSize: "1.3rem" }}>*</span>
                </h6>
              </div>

              <div className="col-12">
                <div className="form-check checkbox">
                  <input
                    className="form-check-input sqare"
                    type="checkbox"
                    id="gridCheck1"
                    name="pregnant"
                    value="Pregnant or nursing mother"
                    // checked={allFields.pregnant}
                    onChange={handleChange}

                  />
                  <label className="form-check-label" htmlFor="gridCheck1">
                    Pregnant or nursing mother
                  </label>
                </div>

                <div className="form-check checkbox">
                  <input
                    className="form-check-input sqare"
                    type="checkbox"
                    id="gridCheck2"
                    name="acuteInfection"
                    value="Acute infection"
                    // checked={allFields.acuteInfection}
                    onChange={handleChange}

                  />
                  <label className="form-check-label" htmlFor="gridCheck2">
                    Acute infection
                  </label>
                </div>

                <div className="form-check checkbox">
                  <input
                    className="form-check-input sqare"
                    type="checkbox"
                    id="gridCheck3"
                    name="immunosuppressants"
                    value="Currently taking immunosuppressants"
                    // checked={allFields.immunosuppressants}
                    onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="gridCheck3">
                    Currently taking immunosuppressants
                  </label>
                </div>

                <div className="form-check checkbox">
                  <input
                    className="form-check-input sqare"
                    type="checkbox"
                    id="gridCheck4"
                    name="electricalImplants"
                    value="Electrical implants"
                    // checked={allFields.electricalImplants}
                    onChange={handleChange}
                  />

                  <label className="form-check-label" htmlFor="gridCheck4">
                    Electrical implants (pacemaker, cochlear implants, etc)
                  </label>
                </div>

                <div className="form-check checkbox">
                  <input
                    className="form-check-input sqare"
                    type="checkbox"
                    id="gridCheck5"
                    name="dvt"
                    value="DVT"
                    // checked={allFields.dvt}
                    onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="gridCheck5">
                    {" "}
                    DVT{" "}
                  </label>
                </div>

                <div className="form-check checkbox">
                  <input
                    className="form-check-input sqare"
                    type="checkbox"
                    id="gridCheck6"
                    name="cardiacEdema"
                    value="Cardiac Edema"
                    // checked={allFields.cardiacEdema}
                    onChange={handleChange}

                  />
                  <label className="form-check-label" htmlFor="gridCheck6">
                    Cardiac Edema
                  </label>
                </div>

                <div className="form-check checkbox">
                  <input
                    className="form-check-input sqare"
                    type="checkbox"
                    id="gridCheck7"
                    name="renalFailure"
                    value="Renal Failure"
                    // checked={allFields.renalFailure}
                    onChange={handleChange}

                  />
                  <label className="form-check-label" htmlFor="gridCheck7">
                    Renal Failure
                  </label>
                </div>

                <div className="form-check checkbox">
                  <input
                    className="form-check-input sqare"
                    type="checkbox"
                    id="gridCheck8"
                    name="any"
                    value="Do not Suffer"
                    // checked={allFields.any}
                    // onChange={handleChange}
                    onClick={(e) =>
                      setShowform(!showform)
                    }
                  />
                  <label className="form-check-label" htmlFor="gridCheck8">
                    Do not Suffer
                  </label>
                  {
                    showform ? <><span style={{ color: "red", fontSize: "1.3rem" }}>*</span><textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows={3}
                      defaultValue="No"
                      name="showinput"
                      required={showform}
                      value={allFields.showinput}
                      onChange={handleChange}
                    /></> : ''

                  }
                </div>

              </div>

              <div
                className="col-12  pt-5"
                style={{ borderBottom: "1px solid #f3f3fe" }}
              >
                <h5 className="section2-heads">
                  IF any of the above items apply to you, we will need to make
                  other scheduling arrangements for your safety.{" "}
                </h5>
              </div>

              <div className="col-12 section2-head pt-4">
                <h6>
                  For what reason are you seeking Assisted Lymphatic Therapy?{" "}
                  <span style={{ color: "red", fontSize: "1.3rem" }}>*</span>
                </h6>
              </div>

              <div className="form-check checkbox ">
                <input
                  className="form-check-input"
                  type="radio"
                  id="medicalReasonCheckbox"
                  name="reason"
                  value="Medical reason"
                  required
                  checked={allFields.reason === "Medical reason"}
                  onChange={handleChange}
                />

                <label
                  className="form-check-label"
                  htmlFor="medicalReasonCheckbox"
                >
                  Medical reason
                </label>
              </div>

              <div className="form-check checkbox">
                <input
                  className="form-check-input"
                  type="radio"
                  id="relaxationCheckbox"
                  name="reason"
                  value="Relaxation"
                  checked={allFields.reason === "Relaxation"}
                  onChange={handleChange}

                  required
                />
                <label className="form-check-label" htmlFor="relaxationCheckbox">
                  Relaxation
                </label>
              </div>

              <div className="form-check checkbox">
                <input
                  className="form-check-input"
                  type="radio"
                  id="detoxCheckbox"
                  name="reason"
                  value="Detox"
                  checked={allFields.reason === "Detox"}
                  onChange={handleChange}

                  required
                />
                <label className="form-check-label" htmlFor="detoxCheckbox">
                  Detox
                </label>
              </div>

              <div className="form-check checkbox">
                <input
                  className="form-check-input"
                  type="radio"
                  id="otherCheckbox"
                  name="reason"
                  checked={allFields.reason === "Other"}
                  onChange={handleChange}
                  value="Other"
                  required
                />
                <label className="form-check-label" htmlFor="otherCheckbox">
                  Other
                </label>
              </div>

              <div className="mb-3 section2-head pt-5">
                <label className="form-label section2-head" htmlFor="medicalReason1" style={{ display: "flex" }}>
                  If for a medical reason, please describe your problem including
                  where it is and its severity. &nbsp;
                  <span style={{ color: "red", fontSize: "1.3rem", display: selectOption === "Medical reason" ? "block" : "none" }}>*</span>
                </label>
                <textarea
                  className="form-control"
                  id="medicalReason1"
                  rows={6}
                  defaultValue={""}
                  value={allFields.medicalreason1}
                  onChange={handleChange}
                  name="medicalreason1"
                  required={selectOption === "Medical reason"}
                />
              </div>

              <div className="mb-3 section2-head pt-5">
                <label className="form-label section2-head" htmlFor="surgeries">
                  Please list all surgeries (including Cesarean section and
                  cosmetic){" "}
                  <span style={{ color: "red", fontSize: "1.3rem" }}>*</span>
                </label>
                <textarea
                  className="form-control"
                  id="surgeries"
                  rows={6}
                  defaultValue={""}
                  value={allFields.surgeries}
                  onChange={handleChange}
                  required
                  name="surgeries"
                />
              </div>

              <div
                className="col-12 pt-5"
                style={{
                  fontSize: "1.125em",
                  color: "#2c3345",
                  borderBottom: "1px solid #f3f3fe",
                }}
              >
                <h1>Pick any that apply</h1>
              </div>

              <div className="col-md-6 pt-5">
                <label htmlFor="inputGroupSelect03" className="form-label">
                  Liposuction{" "}
                  <span style={{ color: "red", fontSize: "1.3rem" }}>*</span>
                </label>
                <div className="input-group mb-3">
                  <select
                    value={allFields.liposuction}
                    onChange={handleChange}
                    className="form-select"
                    id="inputGroupSelect03"
                    aria-label="Example select with button addon"
                    required
                    name="liposuction"
                  >
                    <option value="" >Please Select</option>
                    <option value="Abdomen">Abdomen</option>
                    <option value="Waist">Waist</option>
                    <option value="Arms">Arms</option>
                    <option value="Back">Back</option>
                    <option value="Hips">Hips</option>
                    <option value="Thighs">Thighs</option>
                    <option value="N/A">N/A</option>
                  </select>
                </div>
              </div>

              <div className="col-md-6 mt-5">
                <label htmlFor="inputGroupSelect04" className="form-label">
                  Breast{" "}
                  <span style={{ color: "red", fontSize: "1.3rem" }}>*</span>
                </label>
                <div className="input-group">
                  <select
                    value={allFields.breast}
                    onChange={handleChange}
                    className="form-select"
                    id="inputGroupSelect04"
                    aria-label="Example select with button addon"
                    required
                    name="breast"
                  >
                    <option value="" >Please Select</option>
                    <option required value="Agumentation">Agumentation</option>
                    <option required value="Reduction">Reduction</option>
                    <option required value="Lift">Lift</option>
                    <option required value="Removal">Removal</option>
                    <option required value="N/A">N/A</option>
                  </select>
                </div>
              </div>

              <div className="col-12 pt-4">
                <div className="col-md-6">
                  <label htmlFor="otherSelect" className="form-label">
                    Other{" "}
                    <span style={{ color: "red", fontSize: "1.3rem" }}>*</span>
                  </label>
                  <div className="input-group">
                    <select
                      value={allFields.other1}
                      onChange={handleChange}
                      className="form-select"
                      id="otherSelect"
                      aria-label="Example select with button addon"
                      required
                      name="other1"
                    >
                      <option value="">Please Select</option>
                      <option value="Tammy Tuck">Tammy Tuck</option>
                      <option value="BBL">BBL</option>
                      <option value="Hip">Hip</option>
                      <option value="Agumentation">Agumentation</option>
                      <option value="Botox">Botox</option>
                      <option value="N/A">N/A</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="col-12 section2-head pt-5">
                <h6>
                  Please pick a box for each line. Pick "Yes" if you currently or
                  regularly experience the condition listed.{" "}
                  <span style={{ color: "red", fontSize: "1.3rem" }}>*</span>
                </h6>
              </div>

              {/*---Table---*/}

              <div className="col-md-12">
                <table className="table table    ">
                  <thead>
                    <tr>
                      <th scope="col" />
                      <th
                        scope="col"
                        className="table-row"
                        style={{
                          borderLeft: "1px solid",
                          borderTop: "1px solid",
                          borderRight: "1px solid",
                          borderColor: "#b8bdc9",
                          fontSize: ".75em",
                          backgroundColor: "#e5eaf4",
                        }}
                      >
                        Yes
                      </th>
                      <th
                        scope="col"
                        className="table-row"
                        style={{
                          borderLeft: "1px solid",
                          borderTop: "1px solid",
                          borderRight: "1px solid",
                          borderColor: "#b8bdc9",
                          backgroundColor: "#e5eaf4",
                          fontSize: ".75em",
                        }}
                      >
                        No
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    style={{
                      borderLeft: "1px solid",
                      borderTop: "1px solid #b8bdc9",
                      borderColor: "#b8bdc9",
                    }}
                  >
                    <tr required>
                      <td style={{ backgroundColor: "#e5eaf4" }}>
                        Sinus Congestion
                      </td>
                      <td
                        className="table-row"
                        style={{
                          borderLeft: "1px solid",
                          borderTop: "1px solid",
                          borderRight: "1px solid",
                          borderColor: "#b8bdc9",

                        }}
                      >
                        <input
                          className="form-check-input square"
                          type="radio"
                          id="sinuscongestion"
                          name="sinuscongestion"
                          checked={allFields.sinuscongestion === 'Yes'}
                          onChange={handleChange}
                          value="Yes"
                          required
                        />
                      </td>
                      <td
                        className="table-row"
                        style={{
                          borderLeft: "1px solid",
                          borderTop: "1px solid",
                          borderRight: "1px solid",
                          borderColor: "#b8bdc9",
                        }}
                      >
                        <input
                          className="form-check-input square"
                          type="radio"
                          id="gridCheckNo1"
                          name="sinuscongestion"
                          checked={allFields.sinuscongestion === 'No'}
                          onChange={handleChange}
                          value="No"
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ backgroundColor: "#e5eaf4" }}>Sinus Problems</td>
                      <td
                        className="table-row"
                        style={{
                          borderLeft: "1px solid",
                          borderTop: "1px solid",
                          borderRight: "1px solid",
                          borderColor: "#b8bdc9",
                        }}
                      >
                        <input
                          className="form-check-input square"
                          type="radio"
                          id="sinusproblems"
                          name="sinusproblems"
                          checked={allFields.sinusproblems === "Yes"}
                          onChange={handleChange}
                          value="Yes"
                          required
                        />
                      </td>
                      <td className="table-row table-border">
                        <input
                          className="form-check-input square"
                          type="radio"
                          id="sinusproblems1"
                          name="sinusproblems"
                          checked={allFields.sinusproblems === "No"}
                          onChange={handleChange}
                          value="No"
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ backgroundColor: "#e5eaf4" }}>
                        Ear Aches or Ringing
                      </td>
                      <td className="table-row table-border">
                        <input
                          className="form-check-input square"
                          type="radio"
                          id="earaches"
                          name="earaches"
                          checked={allFields.earaches === "Yes"}
                          onChange={handleChange}
                          required
                          value="Yes"
                        />
                      </td>
                      <td className="table-row table-border">
                        <input
                          className="form-check-input square"
                          type="radio"
                          id="earaches1"
                          name="earaches"
                          checked={allFields.earaches === "No"}
                          onChange={handleChange}
                          required
                          value="No"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ backgroundColor: "#e5eaf4" }}>Arthritis</td>
                      <td className="table-row table-border">
                        <input
                          className="form-check-input square"
                          type="radio"
                          id="arthritis"
                          name="arthritis"
                          checked={allFields.arthritis === "Yes"}
                          onChange={handleChange}
                          required
                          value="Yes"
                        />
                      </td>
                      <td className="table-row table-border">
                        <input
                          className="form-check-input square"
                          type="radio"
                          id="arthritis1"
                          name="arthritis"
                          checked={allFields.arthritis === "No"}
                          onChange={handleChange}
                          required
                          value="No"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ backgroundColor: "#e5eaf4" }}>Inflamamation</td>
                      <td className="table-row table-border">
                        <input
                          className="form-check-input square"
                          type="radio"
                          id="inflamamation"
                          name="inflamamation"
                          checked={allFields.inflamamation === "Yes"}
                          onChange={handleChange}
                          required
                          value="Yes"
                        />
                      </td>
                      <td className="table-row table-border">
                        <input
                          className="form-check-input square"
                          type="radio"
                          id="inflamamation1"
                          name="inflamamation"
                          checked={allFields.inflamamation === "No"}
                          onChange={handleChange}
                          required
                          value="No"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ backgroundColor: "#e5eaf4" }}>Fibromyalgia</td>
                      <td className="table-row table-border">
                        <input
                          className="form-check-input square"
                          type="radio"
                          id="fibromyalgia"
                          name="fibromyalgia"
                          checked={allFields.fibromyalgia === "Yes"}
                          onChange={handleChange}
                          required
                          value="Yes"
                        />
                      </td>
                      <td className="table-row table-border">
                        <input
                          className="form-check-input square"
                          type="radio"
                          id="fibromyalgia1"
                          name="fibromyalgia"
                          checked={allFields.fibromyalgia === "No"}
                          onChange={handleChange}
                          required
                          value="No"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ backgroundColor: "#e5eaf4" }}>Major Scars</td>
                      <td className="table-row table-border">
                        <input
                          className="form-check-input square"
                          type="radio"
                          id="majorscars"
                          name="majorscars"
                          checked={allFields.majorscars === "Yes"}
                          onChange={handleChange}
                          required
                          value="Yes"
                        />
                      </td>
                      <td className="table-row table-border">
                        <input
                          className="form-check-input square"
                          type="radio"
                          id="majorscars1"
                          name="majorscars"
                          checked={allFields.majorscars === "No"}
                          onChange={handleChange}
                          required
                          value="No"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ backgroundColor: "#e5eaf4" }}>Varicose Veins</td>
                      <td className="table-row table-border">
                        <input
                          className="form-check-input square"
                          type="radio"
                          id="varicoseveins"
                          name="varicoseveins"
                          checked={allFields.varicoseveins === "Yes"}
                          onChange={handleChange}
                          required
                          value="Yes"
                        />
                      </td>
                      <td className="table-row table-border">
                        <input
                          className="form-check-input square"
                          type="radio"
                          id="varicoseveins1"
                          name="varicoseveins"
                          checked={allFields.varicoseveins === "No"}
                          onChange={handleChange}
                          required
                          value="No"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ backgroundColor: "#e5eaf4" }}>Strokes</td>
                      <td className="table-row table-border">
                        <input
                          className="form-check-input square"
                          type="radio"
                          id="strokes"
                          name="strokes"
                          checked={allFields.strokes === "Yes"}
                          onChange={handleChange}
                          required
                          value="Yes"
                        />
                      </td>
                      <td className="table-row table-border">
                        <input
                          className="form-check-input square"
                          type="radio"
                          id="strokes1"
                          name="strokes"
                          checked={allFields.strokes === "No"}
                          onChange={handleChange}
                          required
                          value="No"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ backgroundColor: "#e5eaf4" }}>Seizures</td>
                      <td className="table-row table-border">
                        <input
                          className="form-check-input square"
                          type="radio"
                          id="seizures"
                          name="seizures"
                          checked={allFields.seizures === "Yes"}
                          onChange={handleChange}
                          required
                          value="Yes"
                        />
                      </td>
                      <td className="table-row table-border">
                        <input
                          className="form-check-input square"
                          type="radio"
                          id="seizures1"
                          name="seizures"
                          checked={allFields.seizures === "No"}
                          onChange={handleChange}
                          required
                          value="No"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ backgroundColor: "#e5eaf4" }}>
                        Fibrocystic Breast
                      </td>
                      <td className="table-row table-border">
                        <input
                          className="form-check-input square"
                          type="radio"
                          id="fibrocysticbreast"
                          name="fibrocysticbreast"
                          checked={allFields.fibrocysticbreast === "Yes"}
                          onChange={handleChange}
                          required
                          value="Yes"
                        />
                      </td>
                      <td className="table-row table-border">
                        <input
                          className="form-check-input square"
                          type="radio"
                          id="fibrocysticbreast1"
                          name="fibrocysticbreast"
                          checked={allFields.fibrocysticbreast === "No"}
                          onChange={handleChange}
                          required
                          value="No"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ backgroundColor: "#e5eaf4" }}>
                        Currently Pregnant
                      </td>
                      <td className="table-row table-border">
                        <input
                          className="form-check-input square"
                          type="radio"
                          id="currentlypregnant"
                          name="currentlypregnant"
                          checked={allFields.currentlypregnant === "Yes"}
                          onChange={handleChange}
                          required
                          value="Yes"
                        />
                      </td>
                      <td className="table-row table-border">
                        <input
                          className="form-check-input square"
                          type="radio"
                          id="currentlypregnant1"
                          name="currentlypregnant"
                          checked={allFields.currentlypregnant === "No"}
                          onChange={handleChange}
                          required
                          value="No"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ backgroundColor: "#e5eaf4" }}>
                        Currently Breastfeeding
                      </td>
                      <td className="table-row table-border">
                        <input
                          className="form-check-input square"
                          type="radio"
                          id="currentlybreastfeeding"
                          name="currentlybreastfeeding"
                          checked={allFields.currentlybreastfeeding === "Yes"}
                          onChange={handleChange}
                          required
                          value="Yes"
                        />
                      </td>
                      <td className="table-row table-border">
                        <input
                          className="form-check-input square"
                          type="radio"
                          id="currentlybreastfeeding1"
                          name="currentlybreastfeeding"
                          checked={allFields.currentlybreastfeeding === "No"}
                          onChange={handleChange}
                          required
                          value="No"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ backgroundColor: "#e5eaf4" }}>DVT</td>
                      <td className="table-row table-border">
                        <input
                          className="form-check-input square"
                          type="radio"
                          id="ch_dvt"
                          name="ch_dvt"
                          checked={allFields.ch_dvt === "Yes"}
                          onChange={handleChange}
                          required
                          value="Yes"
                        />
                      </td>
                      <td className="table-row table-border">
                        <input
                          className="form-check-input square"
                          type="radio"
                          id="ch_dvt_1"
                          name="ch_dvt"
                          checked={allFields.ch_dvt === "No"}
                          onChange={handleChange}
                          required
                          value="No"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="mb-3 pt-4">
                <label className="form-label section2-head" htmlFor="lymphedema">
                  Have you ever been diagnosed with Lymphedema? Is so, please
                  describe the extremities that are affected.{" "}
                  <span style={{ color: "red", fontSize: "1.3rem" }}>*</span>
                </label>
                <textarea
                  className="form-control"
                  id="lymphedema"
                  rows={6}
                  defaultValue={""}
                  required
                  value={allFields.lymphedema}
                  onChange={handleChange}
                  name="lymphedema"
                />
              </div>

              <div className="mb-3 pt-4">
                <label className="form-label section2-head" htmlFor="lymphNodes">
                  Have you had Lymph Nodes removed? If yes, please describe how
                  many and the location of the removal.{" "}
                  <span style={{ color: "red", fontSize: "1.3rem" }}>*</span>
                </label>
                <textarea
                  className="form-control"
                  id="lymphNodes"
                  rows={6}
                  defaultValue={""}
                  value={allFields.lymphNodes}
                  onChange={handleChange}
                  required
                  name="lymphNodes"
                />
              </div>

              <div className="mb-3 pt-4">
                <label className="form-label section2-head" htmlFor="needs">
                  Is there is anything else that I should know about you or your
                  needs before the session? (Pain, tenderness, other medical
                  conditions, etc.){" "}
                  <span style={{ color: "red", fontSize: "1.3rem" }}>*</span>
                </label>
                <textarea
                  className="form-control"
                  id="needs"
                  rows={6}
                  defaultValue={""}
                  value={allFields.needs}
                  onChange={handleChange}
                  name="needs"
                  required
                />
              </div>

              <div className="col-12 pt-5 ">
                <div className="form-check checkbox">
                  {/* <p style={{ color: "red" }}>*</p> */}
                  <input
                    className="form-check-input sqare"
                    type="checkbox"
                    id="ch_box_1"
                    required
                    name="ch_box_1"
                    value="Checkbox 1 Checked"
                    // checked={allFields.ch_box_1}
                    onChange={handleChange}
                  />

                  <label className="form-check-label" htmlFor="ch_box_1">
                    I understand that the Assisted Lymphatic Drainage I receive is
                    provided for the basic purpose of improving the flow of my
                    lymphatic system. If I experience any pain or discomfort
                    during this session, I will immediately inform the
                    practitioner.
                    <span style={{ color: "red", fontSize: "1.3rem" }}>*</span>
                  </label>
                </div>
              </div>

              <div className="col-12 mt-5">
                <div className="form-check checkbox">
                  <input
                    className="form-check-input sqare"
                    type="checkbox"
                    id="ch_box_2"
                    required
                    name="ch_box_2"
                    value="Checkbox 2 Checked"
                    // checked={allFields.ch_box_2}
                    onChange={handleChange}

                  />
                  I further understand that Assisted Lymphatic Therapy should not
                  be construed as a substitute for medical examination,
                  diagnosis, or treatment and that I should see a physician or
                  other qualified medical specialist for any mental or physical
                  ailment of which I am aware. I understand that Assisted
                  Lymphatic Therapists are not qualified to diagnose, prescribe,
                  or treat any physical or mental illness, and that nothing said
                  in the course of the session given should be construed as
                  such.
                  <span style={{ color: "red", fontSize: "1.3rem" }}>*</span>
                </div>
              </div>

              <div className="col-12 mt-5">
                <div className="form-check checkbox">
                  <input
                    className="form-check-input sqare"
                    type="checkbox"
                    id="ch_box_3"
                    value="Checkbox 3 Checked"
                    required
                    name="ch_box_3"
                    // checked={allFields.ch_box_3}
                    onChange={handleChange}
                  />
                  Because Assisted Lymphatic Therapy should not be performed
                  under certain medical conditions, I affirm that I have
                  stated all my known medical conditions and answered all
                  questions honestly.
                  <span style={{ color: "red", fontSize: "1.3rem" }}>*</span>
                </div>
                <div className="col-12 mt-5">
                  <div className="form-check checkbox">
                    <input
                      className="form-check-input sqare"
                      type="checkbox"
                      id="ch_box_4"
                      value="Checkbox 4 Checked"
                      required
                      name="ch_box_4"
                      // checked={allFields.ch_box_4}
                      onChange={handleChange}
                    />
                    I agree to keep the practitioner updated as to any changes
                    in my medical profile and understand that there shall be
                    no liability on the practitioner’s part should I fail to
                    do so.
                    <span style={{ color: "red", fontSize: "1.3rem" }}>
                      *
                    </span>
                  </div>
                </div>
                <div className="form-check checkbox mt-5" required>
                  <p>
                    * Please Note: Assisted Lymphatic Therapy is a very
                    powerful modality and certain medical conditions are
                    contraindicated and will determine if and when you can
                    receive a session. After the consultation and review of
                    the information you have provided on this form, it will be
                    determined if Assisted Lymphatic Therapy should be
                    administered to you today. Some conditions will require a
                    note from your doctor before proceeding. Please understand
                    this is for your safety and well-being.
                  </p>
                  <p>
                    The information I provided is true and to the best of my
                    knowledge.
                  </p>
                </div>
              </div>

              <div className="row">
                <div className=" pt-5">
                  <div className="col-md-6">
                    <label htmlFor="printName" className="form-label label">
                      Print Name <span style={{ color: "red", fontSize: "1.3rem" }}>
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-control inputs"
                      id="printName"
                      required
                      value={allFields.printname}
                      onChange={handleChange}
                      name="printname"
                    />
                  </div>
                </div>

                <div className=" pt-5 pb-3" style={{ borderBottom: "1px solid #f3f3fe" }}>
                  <div className="col-md-6" >
                    <label htmlFor="date" className="form-label label">
                      Date
                    </label>
                    <input
                      type="date"
                      className="form-control inputs "
                      id="date"
                      required
                      name="ldate"
                      value={allFields.ldate}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-12 mt-5" style={{ textAlign: "center" }}>
                  <button

                    type="submit"
                    className="btn  btn-lg"
                    style={{
                      backgroundColor: "#18bd5b",
                      color: "white",
                      width: 225,
                      borderRadius: 5,
                      marginBottom: 25,
                    }}
                    onClick={(e) => { handleSubmit(e) }}
                  >
                    {loading ? "Submitting..." : "Submit"}
                  </button>
                </div>
              </div>

            </div>
          </form>
        </div>
      </div>
    </div>
  );
}